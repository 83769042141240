<template>
  <v-container>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">{{
          $t('dialogMessages.itemDeleteConfirmation')
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">{{
            $t('buttons.cancel')
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{
            $t('buttons.ok')
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn class="success" @click="downloadCsv">{{
      $t('buttons.exportClaims')
    }}</v-btn>
    <v-row no-gutters>
      <v-col cols="3">
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          v-model="searchString"
          :placeholder="$t('placeholders.search')"
          clearable
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="10" md="8">
        <v-chip-group
          v-model="filters.selection"
          multiple
          active-class="primary--text"
        >
          <v-chip v-if="!isClient" value="Followed" @click="handleFilterClick">
            {{ $t('filters.followed') }}
          </v-chip>
          <v-chip value="Open" @click="handleFilterClick">
            {{ $t('filters.open') }}
          </v-chip>
          <v-chip value="Closed" @click="handleFilterClick">
            {{ $t('filters.closed') }}
          </v-chip>
          <v-chip v-if="!isClient" value="New" @click="handleFilterClick">
            {{ $t('filters.new') }} {{ newClaimCounter }}
          </v-chip>
          <v-chip value="2023" @click="handleFilterClick"> 2023 </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="3">
        <v-autocomplete
          dense
          v-if="clients"
          :items="clientSelectItems"
          v-model="filters.client"
          :label="$t('filters.client')"
          clearable
          @change="handleFilterClick"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          dense
          v-if="stages"
          :items="stageItems"
          v-model="filters.stage"
          :label="$t('filters.stage')"
          @change="handleFilterClick"
          clearable
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-menu
          v-model="fromDatepicker_show"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              v-model="filters.fromDate"
              :label="$t('filters.from')"
              v-on="on"
              clearable
              @change="handleFilterClick"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.fromDate"
            @input="fromDatepicker_show = false"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-menu
          v-model="toDatepicker_show"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              v-model="filters.toDate"
              :label="$t('filters.to')"
              v-on="on"
              clearable
              @change="handleFilterClick"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.toDate"
            @input="toDatepicker_show = false"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pagination.pages || 1"
        @input="handlePaginationInput"
      ></v-pagination>
    </div>
    <v-data-table
      class="elevation-1"
      :headers="_headersWithActions"
      :items="sortedClaimsWithDuration"
      :item-class="classChecker"
      :loading="isLoading"
      :header-props="headerProps"
      :footer-props="footerOptions"
      :server-items-length="pagination.records || -1"
      :items-per-page.sync="itemsPerPage"
      :options.sync="options"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          :pagination.sync="pagination"
          :options="pagination"
          @update:options="updateOptions"
          :items-per-page-options="footerOptions.itemsPerPageOptions"
        />
      </template>
      <template v-slot:item.id="{ item }">
        <router-link :to="`/mtpl/${item.id}/`">
          <span>{{
            item.claimNumber ? item.claimNumber : item.id
          }}</span></router-link
        >
      </template>
      <template v-slot:item.outcome="{ item }">
        <span>
          {{ truncate(item.outcome, 8) }}
        </span>
      </template>
      <template v-slot:item.contacts="{ item }">
        <span>
          {{ truncate(item.contacts, 8) }}
        </span>
      </template>
      <template v-slot:item.contactsNotEmpty="{ item }">
        <span>
          {{ isEmpty(item.contacts) }}
        </span>
      </template>
      <template v-slot:item.description="{ item }">
        <span>
          {{ truncate(item.description, 8) }}
        </span>
      </template>
      <template v-slot:item.circumstance.name="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ circumstanceName(item) }}
            </span>
          </template>
          <span>
            {{ circumstanceTooltip(item) }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.incidentDate="{ item }">
        <span>
          {{
            item.incidentDate == '0001-01-01T00:00:00Z'
              ? ''
              : formatDate(item.incidentDate)
          }}
        </span>
      </template>
      <template v-slot:item.stageChangeDate="{ item }">
        <span>
          {{ item.stageChangeDate ? formatDate(item.stageChangeDate) : '' }}
        </span>
      </template>
      <template v-slot:item.reportedDate="{ item }">
        <span>
          {{
            item.reportedDate == '0001-01-01T00:00:00Z'
              ? ''
              : formatDate(item.reportedDate)
          }}
        </span>
      </template>
      <template v-slot:item.stage.displayName="{ item }">
        <span :class="stageColor(item.stage.displayName)">
          {{ $t(`stages["${item.stage.displayName}"]`) }}
        </span>
      </template>
      <template v-slot:item.amountPaid="{ item }">
        <span>
          {{ formatAmount(item.amountPaid) }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon v-if="isAdmin" small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pagination.pages || 1"
        @input="handlePaginationInput"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)
import {
  removeUnwantedSeparators,
  getTimeDifferenceInDays,
  getTimeDifferenceInDaysUntilNow,
} from './../utils/formattingHelpers'
import { exportCsv } from './../utils/MTPLExportCSV'
import { filterOutFollowers } from './../utils/exportUtils'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      isLoading: true,
      hideHeadersFromClients: [],
      fromDatepicker_show: false,
      toDatepicker_show: false,
      dialogDelete: false,
      dialog: false,
      editedIndex: -1,
      editedItem: {},
      filters: {
        selection: [],
        client: '',
        fromDate: '',
        toDate: '',
        stage: '',
      },
      searchString: '',
      filtersLoaded: false,
      options: {},
      page: 1,
      itemsPerPage: 25,
      footerOptions: {
        itemsPerPageOptions: [25, 50, 100, -1],
      },
    }
  },
  created() {
    this.$store.dispatch('client/getClients')

    // if (sessionStorage.getItem('MTPLFilters')) {
    //   let JSONFilters = sessionStorage.getItem('MTPLFilters')
    //   let filters = JSON.parse(JSONFilters)
    //   // TODO: Implement chip active change.
    //   this.$nextTick(() => {
    //     this.filters = Object.assign({}, this.filters, filters)
    //   })
    // }
  },
  mounted() {
    this.$store.dispatch('stage/getStages', 1)

    this.localStorageLoad()
  },
  beforeDestroy() {
    this.$store.dispatch('mtpl/clearClaims')
    localStorage.setItem('MTPLFilters', JSON.stringify(this.filters))
    localStorage.setItem('MTPLItemsPerPage', JSON.stringify(this.itemsPerPage))
    localStorage.setItem('MTPLPage', JSON.stringify(this.page))
  },
  beforeUnmount() {},
  computed: {
    ...mapState({
      pagination: (state) => state.mtpl.pagination,
      mtpls: (state) => state.mtpl.mtpls,
      user: (state) => state.user.user,
      clients: (state) => state.client.clients,
      stages: (state) => state.stage.stages,
    }),
    headers() {
      return [
        {
          text: this.$i18n.t('headers.claim'),
          value: 'id',
        },
        {
          text: this.$i18n.t('headers.client'),
          value: 'client.name',
        },
        {
          text: this.$i18n.t('headers.vehicleNumber'),
          value: 'vehicle.registration',
        },
        {
          text: this.$i18n.t('headers.incidentDate'),
          value: 'incidentDate',
        },
        {
          text: this.$i18n.t('headers.circumstance'),
          value: 'circumstance.name',
        },
        {
          text: this.$i18n.t('headers.driver'),
          value: 'driver.name',
        },
        {
          text: this.$i18n.t('headers.location'),
          value: 'incidentLocation',
        },
        {
          text: this.$i18n.t('headers.stage'),
          value: 'stage.displayName',
        },
        {
          text: this.$i18n.t('headers.stageChangeDate'),
          value: 'stageChangeDate',
        },
        {
          text: this.$i18n.t('headers.icn'),
          value: 'insuranceClaimNumber',
        },
        {
          text: this.$i18n.t('headers.paid'),
          value: 'amountPaid',
        },
        {
          text: this.$i18n.t('headers.duration'),
          value: 'duration',
          sortable: false,
        },
      ]
    },

    headersWithActions() {
      return [
        ...this.headers,
        {
          text: this.$i18n.t('headers.contacts'),
          value: 'contactsNotEmpty',
          sortable: false,
        },
        {
          text: this.$i18n.t('headers.actions'),
          value: 'actions',
          sortable: false,
        },
      ]
    },

    stageItems() {
      let stages = this.stages
      return stages.map((stage) => {
        return {
          value: stage.id,
          text: this.$i18n.t(`stages["${stage.displayName.trim()}"]`),
        }
      })
    },
    filteredClaims() {
      let conditions = []

      // this.filters.selection.includes('Open') &&
      //   conditions.push(this.filterOpenClaims)
      // this.filters.selection.includes('Closed') &&
      //   conditions.push(this.filterClosedClaims)
      // this.filters.selection.includes('Followed') &&
      //   conditions.push(this.filterFollowedClaims)
      // this.filters.selection.includes('2023') &&
      //   conditions.push(this.filterCurrentYearClaims)
      // this.filters.selection.includes('New') &&
      //   conditions.push(this.filterNewClientClaims)

      // if (this.searchString !== '' && this.searchString != null) {
      //   // conditions.push(this.filterSearch)
      // }

      // if (this.filters.client !== '' && this.filters.client != null) {
      //   conditions.push(this.filterClaimClient)
      // }

      // if (this.filters.fromDate !== '' && this.filters.fromDate != null) {
      //   conditions.push(this.filterClaimFromDate)
      // }

      // if (this.filters.toDate !== '' && this.filters.toDate != null) {
      //   conditions.push(this.filterClaimToDate)
      // }

      if (conditions.length > 0 && this.mtpls.claims) {
        return this.mtpls.claims.filter((claim) => {
          return conditions.every((condition) => {
            return condition(claim)
          })
        })
      }
      return this.mtpls.claims
    },

    newClaimCounter() {
      if (!this.mtpls?.claims && this.mtpls.claims.length === 0) {
        return `(0)`
      } else {
        let count = 0
        count = this.mtpls.claims.filter((item) => {
          return (
            item.createdByClient &&
            (item.claimNumber == null || item.claimNumber == '')
          )
        }).length
        return `(${count})`
      }
    },

    _headersWithActions() {
      return this.headersWithActions.filter(
        (h) => !this.hideHeadersFromClients.includes(h.value) || !this.isClient
      )
    },
    sortedClaims() {
      if (this.filteredClaims) {
        return this.filteredClaims.concat().sort(this.sortClaimsDefault)
      } else {
        return this.filteredClaims
      }
    },
    sortedClaimsWithDuration() {
      return this.sortedClaims.map((c) => {
        return {
          ...c,
          duration: c.processDuration
            ? getTimeDifferenceInDays(c.reportedDate, c.processDuration)
            : getTimeDifferenceInDaysUntilNow(c.reportedDate),
        }
      })
    },
    isClient() {
      return this.$store.getters['user/role'] == 'client'
    },
    isAdmin() {
      return this.$store.getters['user/role'] == 'admin'
    },
    headerProps() {
      return {
        sortByText: this.$i18n.t(`dataTable.sortBy`),
      }
    },
    clientSelectItems() {
      return this.clients.map((c) => ({
        text: c.name,
        value: c.id,
      }))
    },
  },
  methods: {
    classChecker(item) {
      if (item.createdByClient) {
        return 'custom-bg'
      } else {
        return ''
      }
    },
    filterFollowedClaims(item) {
      return item.followers.map((f) => f.id).includes(this.user.id)
    },
    filterClosedClaims(item) {
      return item.stage.isFinal
    },
    filterOpenClaims(item) {
      return !item.stage.isFinal
    },
    filterCurrentYearClaims(item) {
      const yearToCheck = 2023
      return dayjs(item.reportedDate).isSame(`${yearToCheck}`, 'year')
    },
    filterNewClientClaims(item) {
      return (
        item.createdByClient &&
        (item.claimNumber == null || item.claimNumber == '')
      )
    },
    getCurrentTimeString() {
      let timestamp = new Date()
      return (
        '' +
        timestamp.getFullYear() +
        timestamp.getMonth() +
        timestamp.getDay() +
        timestamp.getHours() +
        timestamp.getMinutes() +
        timestamp.getSeconds()
      )
    },
    filterClaimClient(item) {
      return item.client.id == this.filters.client
    },
    filterSearch(item) {
      return (
        item.client?.name
          .toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.claimNumber
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.id.toString().includes(this.searchString) ||
        item.driver?.name
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.vehicle?.registration
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.stage?.displayName
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.incidentDate?.includes(this.searchString) ||
        item.reportedDate?.includes(this.searchString) ||
        item.incidentLocation
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.description
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.insuranceClaimNumber
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.amountPaid?.toString().includes(this.searchString) ||
        (item.circumstance?.name &&
          this.$i18n
            .t(`circumstances["${item.circumstance?.name}"]`)
            ?.toLowerCase()
            .includes(this.searchString.toLowerCase())) ||
        (item.stage?.displayName &&
          this.$i18n
            .t(`stages["${item.stage?.displayName}"]`)
            .toLowerCase()
            .includes(this.searchString.toLowerCase()))
      )
    },
    filterClaimFromDate(item) {
      let fromDate = dayjs(this.filters.fromDate)
      let createdDate = dayjs(item.reportedDate)
      return createdDate.diff(fromDate) >= 0
    },
    filterClaimToDate(item) {
      let toDate = dayjs(this.filters.toDate)
      let createdDate = dayjs(item.reportedDate)
      return toDate.diff(createdDate) >= 0
    },
    handlePaginationInput(page) {
      this.page = page
      this.loadData()
    },
    localStorageLoad() {
      let storagePromises = []

      // Handle Page
      if (localStorage.getItem('MTPLPage')) {
        let JSONPage = localStorage.getItem('MTPLPage')
        try {
          let page = JSON.parse(JSONPage)
          if (parseInt(page)) {
            storagePromises.push(
              new Promise((resolve) => {
                this.$nextTick(() => {
                  this.page = page
                  this.options.page = page
                  resolve() // Resolve when page is set
                })
              })
            )
          }
        } catch (error) {
          console.error('Error parsing MTPLPage from localStorage', error)
        }
      }

      // Handle Items Per Page
      if (localStorage.getItem('MTPLItemsPerPage')) {
        let JSONItemsPerPage = localStorage.getItem('MTPLItemsPerPage')
        try {
          let itemsPerPage = JSON.parse(JSONItemsPerPage)
          if (parseInt(itemsPerPage)) {
            storagePromises.push(
              new Promise((resolve) => {
                this.$nextTick(() => {
                  this.itemsPerPage = itemsPerPage
                  this.options.itemsPerPage = itemsPerPage
                  resolve() // Resolve when itemsPerPage is set
                })
              })
            )
          }
        } catch (error) {
          console.error(
            'Error parsing MTPLItemsPerPage from localStorage',
            error
          )
        }
      }

      if (localStorage.getItem('MTPLFilters')) {
        let JSONFilters = localStorage.getItem('MTPLFilters')
        try {
          let filters = JSON.parse(JSONFilters)
          storagePromises.push(
            new Promise((resolve) => {
              this.$nextTick(() => {
                this.filters = Object.assign({}, this.filters, filters)
                resolve() // Resolve when filters are set
              })
            })
          )
        } catch (error) {
          console.error('Error parsing MTPLFilters from localStorage', error)
        }
      } else {
        this.filters.selection = this.isClient ? ['Open'] : ['Followed']
      }
      Promise.all(storagePromises).then(() => {
        this.$nextTick(() => {
          this.filtersLoaded = true
          this.loadData()
        })
      })
    },
    loadData() {
      if (this.filtersLoaded) {
        let pageSize
        if (this.itemsPerPage == -1) {
          pageSize = 10000
        } else {
          pageSize = this.itemsPerPage
        }
        this.$store.dispatch('mtpl/clearClaims')
        this.$nextTick(() => {
          this.isLoading = true
        })

        this.$store
          .dispatch('mtpl/getClaims', {
            pipelineId: 1,
            orderBy: 'reportedDate',
            page: this.page || 1,
            pageSize: pageSize,
            filters: this.filters,
            claimSearch: this.searchString?.length > 2 ? this.searchString : '',
          })
          .then(
            this.$nextTick(() => {
              this.isLoading = false
            })
          )
      }
    },
    downloadCsv() {
      let dataToExport = this.sortedClaimsWithDuration.map((c) => ({
        claim: c.claimNumber ? c.claimNumber : c.id,
        client: this.nullCheck(c.client.name),
        VehicleRegistration: c.vehicle?.registration
          ? c.vehicle.registration
          : '',
        incidentDate:
          c.incidentDate == '0001-01-01T00:00:00Z'
            ? ''
            : this.formatDate(this.nullCheck(c.incidentDate)),
        circumstance: c.circumstance ? c.circumstance.name : '',
        reportedDate:
          c.reportedDate == '0001-01-01T00:00:00Z'
            ? ''
            : this.formatDate(this.nullCheck(c.reportedDate)),
        driver: c.driver?.name ? c.driver.name : '',
        description: removeUnwantedSeparators(this.nullCheck(c.description)),
        location: removeUnwantedSeparators(this.nullCheck(c.incidentLocation)),
        stage: this.nullCheck(c.stage.displayName),
        stageChangeDate: c.stageChangeDate
          ? this.formatDate(c.stageChangeDate)
          : '',
        insuranceClaimNumber: removeUnwantedSeparators(
          this.nullCheck(c.insuranceClaimNumber)
        ),
        amountPaid: c.amountPaid ? this.formatAmount(c.amountPaid) : '',
        contacts: removeUnwantedSeparators(this.nullCheck(c.contacts)),
        contactsEntered: this.nullCheck(c.contacts).length > 0 ? 'Y' : 'N',
        duration: this.nullCheck(c.duration),
        followers: filterOutFollowers(c.followers),
      }))

      // exportCsv(dataToExport)
      const link = document.createElement('a')
      link.setAttribute('href', exportCsv(dataToExport))
      link.setAttribute(
        'download',
        `claimExport ${this.getCurrentTimeString()}.csv`
      )
      link.click()
    },

    nullCheck(value) {
      return value ? value : ''
    },
    truncate(input, length = 5) {
      if (input != null) {
        return input.length > length
          ? `${input.substring(0, length)}...`
          : input
      }
      return input
    },

    isEmpty(value) {
      if (value != null && value.length > 0) {
        return 'Y'
      }
      return 'N'
    },
    sortClaimsDefault(a, b) {
      let aCondition = a.createdByClient && a.claimNumber === ''
      let bCondition = b.createdByClient && b.claimNumber === ''
      if (Number(aCondition) < Number(bCondition)) {
        return 1
      }
      if (Number(aCondition) > Number(bCondition)) {
        return -1
      }
      return 0
    },
    formatAmount(amount) {
      if (amount > 0) {
        let numString = parseFloat((amount / 100).toFixed(2)).toLocaleString(
          'lt-LT'
        )
        if (!numString.includes(',')) {
          numString += ',00'
        } else if (numString.indexOf(',') == numString.length - 2) {
          numString += '0'
        }
        numString = numString.replace(/\s/g, '')
        return numString
      } else {
        return amount
      }
    },
    stageColor(displayName) {
      switch (displayName) {
        case 'Missing contact details':
          return 'red--text'
        case 'Large claim':
          return 'red--text'
        case 'Reported by insurer':
          return 'red--text'
        case 'Agreed, but unpaid':
          return 'orange--text'
        case 'Paid/Closed':
          return 'green--text'
        default:
          return ''
      }
    },
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD')
    },
    deleteItem(item) {
      this.editedIndex = item.id
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.$store.dispatch('mtpl/deleteClaim', this.editedIndex)
      this.closeDelete()
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    editItem(item) {
      this.editedIndex = this.mtpls.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    circumstanceTooltip(item) {
      return item.circumstance
        ? this.$i18n.t(`circumstances.${item.circumstance.name}`)
        : ''
    },

    circumstanceName(item) {
      return item.circumstance
        ? this.truncate(
            this.$i18n.t(`circumstances.${item.circumstance.name}`),
            8
          )
        : ''
    },
    handleFilterClick() {
      this.page = 1
      this.options.page = 1
    },
  },
  watch: {
    options(options) {
      this.page = options.page
      this.itemsPerPage = options.itemsPerPage
      this.loadData()
    },
    searchString() {
      if (this.searchString?.length > 2) {
        this.loadData()
      } else if (!this.searchString) {
        this.loadData()
      }
    },
    filters: {
      handler() {
        localStorage.setItem('MTPLFilters', JSON.stringify(this.filters))
        localStorage.setItem(
          'MTPLItemsPerPage',
          JSON.stringify(this.itemsPerPage)
        )
        localStorage.setItem('MTPLPage', JSON.stringify(this.page))

        this.loadData()
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss">
.custom-bg {
  background-color: #f7ebe3;
}
</style>
